import { get, post } from "../axiosconfig/axiostool";
import { compressAccurately } from "@/utils/DataURLtoFile";

export default {
  fetchVehicleList() {
    return get("/vehicle-butler/api/vehicles");
  },

  fetchAllVehicleList(pageSize, pageNumber, counted, assignedToMe) {
    return get(
      `/vehicle-butler/api/vehicles/all?pageSize=${pageSize}&pageNumber=${pageNumber}&counted=${counted}&is-assign-to-me=${assignedToMe}`
    );
  },

  fetchVehicleDetail(vehicleId) {
    return get("/vehicle-butler/api/vehicle/" + vehicleId);
  },

  searchVehicleList(keyword) {
    return get("/vehicle-butler/api/vehicles/search?isFuzzy=true&q=" + keyword);
  },
  init() {
    let string = (((1 + Math.random()) * 0x10000) | 0)
      .toString(16)
      .substring(1);
    let uuid =
      string +
      string +
      "-" +
      string +
      "-" +
      string +
      "-" +
      string +
      "-" +
      string;
    localStorage.setItem("uuid", uuid);
    return get(
      "/apisecond/connect/oauth2/authorize?appid=ww755fa7ed1d1af941&redirect_uri=https://vehiclebutler.i.mercedes-benz.com/wecom/middle&response_type=code&snsapi_userinfo=snsapi_base&agentid=1000060&state=" +
      uuid +
      "#wechat_redirect"
    );
  },

  uploadStockData(vehicleId, param) {
    return post(
      `/vehicle-butler/api/vehicle-stocks/vehicle/${vehicleId}`,
      param
    );
  },
  toGetSignature(timestamp, nonceStr, url) {
    return get(
      `/vehicle-butler/api/getSingle?timestamp=${timestamp}&noncestr=${nonceStr}&url=${url}&refresh=false`
    );
  },
  toGetSignatureRefresh(timestamp, nonceStr, url) {
    return get(
      `/vehicle-butler/api/getSingle?timestamp=${timestamp}&noncestr=${nonceStr}&url=${url}&refresh=true`
    );
  },
  fetchTicket() {
    return get(
      `/vehicle-butler/api/getTicket`
    );
  },
  sendCode(code, uuid) {
    return get(
      `/vehicle-butler/api/getUserInfoByCode?code=${code}&user_key=${uuid}`
    );
  },
  getRoleInfo(key) {
    return get(`/vehicle-butler/api/getUserInfoByUserKey?user_key=${key}`);
  },
  async uploadImage(key) {
    const fileData = new FormData();
    for (const item of key) {
      // const file = await compressAccurately(item);
      fileData.append("file", item);
    }
    return post(`/vehicle-butler/api/att/upload`, fileData);
  },

  sacnPhoto(param) {
    const fileData = new FormData();
    fileData.append("file", param);
    return post(`/vehicle-butler/api/scanPlateNumber`, fileData);
  },
  sacnVIN(param) {
    const fileData = new FormData();
    fileData.append("file", param);
    return post(`/vehicle-butler/api/scanVINNumber`, fileData);
  },
};
